import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/src/layout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Content = makeShortcode("Content");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Content mdxType="Content">
      <p>{`Inšpiratívne školenia, podnetné konferencie či nezabudnuteľné teambuildingy. Odpútajte sa od konvečných priestorov a usporiadajte pre svojich kolegov, partnerov a priateľov podujatie v náručí prírody.`}</p>
      <p><strong parentName="p">{`Pre vaše firemné podujatie vám je v hoteli Studničky k dispozícii:`}</strong></p>
      <ul>
        <li parentName="ul">{`Reštaurácia s kapacitou 100 osôb`}</li>
        <li parentName="ul">{`Konferenčná miestnosť`}</li>
      </ul>
      <p>{`Naša konferenčná miestnosť vám poskytne dostatok súkromia. Nachádza sa na samostatnom poschodí, vďaka čomu je oddelená od ubytovacej časti. Jej súčasťou je aj kuchynský kút, kde pre vás radi pripravíme malé občerstvenie a kávu na dobitie energie. Konferenčná miestnosť je plne vybavená, k dispozícií vám je aj dataprojektor a flipchart. Priamo z konferenčnej miestnosti môžete vyjsť na priestrannú terasu a počas prestávky si vychutnávať nádherný výhľad na okolitú prírodu.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAgP/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABTlVqKwLCf//EABkQAAMBAQEAAAAAAAAAAAAAAAECAwAREv/aAAgBAQABBQL0MW3ScHbkZhq3QJT/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwGn/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Bh//EABsQAAMAAgMAAAAAAAAAAAAAAAABERAxEiFR/9oACAEBAAY/At42cb14NVkVP//EABsQAAMAAgMAAAAAAAAAAAAAAAABESFhMUHB/9oACAEBAAE/Icsh+ELyUPYcyE+IGJQkuiq42f/aAAwDAQACAAMAAAAQrx//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPxAr/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAECAQE/EKZH/8QAHBABAAMBAAMBAAAAAAAAAAAAAQARITFBUWHB/9oACAEBAAE/EAq0YqOYI44Hj5kuZQKHOQgS1uDETA1guh6+x8lW9W9T8n//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/25cc381756edeade8f798a7227df6ed2/e93cc/69811029_1160057287525484_983508181768470528_o.webp 300w", "/static/25cc381756edeade8f798a7227df6ed2/b0544/69811029_1160057287525484_983508181768470528_o.webp 600w", "/static/25cc381756edeade8f798a7227df6ed2/68fc1/69811029_1160057287525484_983508181768470528_o.webp 1200w", "/static/25cc381756edeade8f798a7227df6ed2/a2303/69811029_1160057287525484_983508181768470528_o.webp 1800w", "/static/25cc381756edeade8f798a7227df6ed2/7c4f2/69811029_1160057287525484_983508181768470528_o.webp 2048w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/25cc381756edeade8f798a7227df6ed2/73b64/69811029_1160057287525484_983508181768470528_o.jpg 300w", "/static/25cc381756edeade8f798a7227df6ed2/3ad8d/69811029_1160057287525484_983508181768470528_o.jpg 600w", "/static/25cc381756edeade8f798a7227df6ed2/6c738/69811029_1160057287525484_983508181768470528_o.jpg 1200w", "/static/25cc381756edeade8f798a7227df6ed2/8b34c/69811029_1160057287525484_983508181768470528_o.jpg 1800w", "/static/25cc381756edeade8f798a7227df6ed2/8fe94/69811029_1160057287525484_983508181768470528_o.jpg 2048w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/25cc381756edeade8f798a7227df6ed2/6c738/69811029_1160057287525484_983508181768470528_o.jpg",
              "alt": "69811029 1160057287525484 983508181768470528 o",
              "title": "69811029 1160057287525484 983508181768470528 o",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAgP/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAH/2gAMAwEAAhADEAAAAZvR1kKUbLf/xAAaEAACAwEBAAAAAAAAAAAAAAAAAgEDBBES/9oACAEBAAEFAoY7JPWM1C2pONCyrw//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwFX/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGxAAAgEFAAAAAAAAAAAAAAAAAAESAhARITH/2gAIAQEABj8CtwlUabRiR//EABoQAQADAAMAAAAAAAAAAAAAAAEAEYEhMVH/2gAIAQEAAT8hG8gzcsWKNrfKIl7rYyc+T//aAAwDAQACAAMAAAAQ9w//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAWEQEBAQAAAAAAAAAAAAAAAAAAEQH/2gAIAQIBAT8QuI//xAAdEAEAAwACAwEAAAAAAAAAAAABABEhUcExYYGx/9oACAEBAAE/EDZ0oVdUy2ho3m/WS9eDMK/YEaDGX25wySr2h5QDzjuf/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/65c08549e2523ba99517e3e6cd95fdb3/e93cc/70851301_1160057174192162_395144112287776768_o.webp 300w", "/static/65c08549e2523ba99517e3e6cd95fdb3/b0544/70851301_1160057174192162_395144112287776768_o.webp 600w", "/static/65c08549e2523ba99517e3e6cd95fdb3/68fc1/70851301_1160057174192162_395144112287776768_o.webp 1200w", "/static/65c08549e2523ba99517e3e6cd95fdb3/a2303/70851301_1160057174192162_395144112287776768_o.webp 1800w", "/static/65c08549e2523ba99517e3e6cd95fdb3/7c4f2/70851301_1160057174192162_395144112287776768_o.webp 2048w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/65c08549e2523ba99517e3e6cd95fdb3/73b64/70851301_1160057174192162_395144112287776768_o.jpg 300w", "/static/65c08549e2523ba99517e3e6cd95fdb3/3ad8d/70851301_1160057174192162_395144112287776768_o.jpg 600w", "/static/65c08549e2523ba99517e3e6cd95fdb3/6c738/70851301_1160057174192162_395144112287776768_o.jpg 1200w", "/static/65c08549e2523ba99517e3e6cd95fdb3/8b34c/70851301_1160057174192162_395144112287776768_o.jpg 1800w", "/static/65c08549e2523ba99517e3e6cd95fdb3/8fe94/70851301_1160057174192162_395144112287776768_o.jpg 2048w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/65c08549e2523ba99517e3e6cd95fdb3/6c738/70851301_1160057174192162_395144112287776768_o.jpg",
              "alt": "70851301 1160057174192162 395144112287776768 o",
              "title": "70851301 1160057174192162 395144112287776768 o",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <p><strong parentName="p">{`Zvoľte si Studničky pre vaše výnimočné podujatie:`}</strong></p>
      <ul>
        <li parentName="ul">{`ubytovanie až pre 40 osôb,`}</li>
        <li parentName="ul">{`výborná domáca kuchyňa,`}</li>
        <li parentName="ul">{`intímne prostredie a absolútne súkromie v objatí prírody,`}</li>
        <li parentName="ul">{`skvelá poloha priamo pri lyžiarskom svahu,`}</li>
        <li parentName="ul">{`4 lyžiarske vleky pre začiatočníkov aj pokročilých (celková dĺžka tratí je 1400 m),`}</li>
        <li parentName="ul">{`technicky zasnežené a denne upravované zjazdovky,`}</li>
        <li parentName="ul">{`celodenný skipass v cene zakúpeného balíka,`}</li>
        <li parentName="ul">{`uschovanie lyžiarskej výstroje priamo v hoteli - vlastná lyžiareň,`}</li>
        <li parentName="ul">{`požičovňa kompletnej lyžiarskej výstroje a servis lyží (za príplatok)`}</li>
        <li parentName="ul">{`množstvo aktivít počas celého roka,`}</li>
        <li parentName="ul">{`parkovanie pri hoteli.`}</li>
      </ul>
      <p>{`V prípade záujmu o podujatie v našom hoteli nás neváhajte `}<a parentName="p" {...{
          "href": "/kontakt/"
        }}>{`kontaktovať`}</a>{`.`}</p>
    </Content>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      